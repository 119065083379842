.header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #0d6efd;
}

.topSection .logo {
  color: white;
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: white;
  color: #333;
}

.logo {
  font-family: 'Pacifico', cursive;
  font-size: 35px;
  display: flex;
  align-items: center;
}

.logo img {
  width: 36px;
  height: 36px;
  margin-right: 0.5rem;
}

.buttons {
  display: flex;
  gap: 0.5rem;
}

.rightContent {
  display: flex;
  align-items: center;
}

.time {
  margin-right: 1rem;
}

.largeFont {
  font-size: 1.15rem; 
}